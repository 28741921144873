import React from "react";
import { Routes, Route } from "react-router-dom";
import RouteDataProvider from "../../data/RouteDataProvider";

const AppRouter = () => {
  return (
    <Routes>
      {RouteDataProvider.getRouterMap().map(({path, element}, idx) => (
        <Route path={path} element={element} key={idx} />
      ))}
    </Routes>
  );
};

export default AppRouter;
