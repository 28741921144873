import React from "react";
import Container from "../../components/Container";
import Navigation from "../../components/Navigation";

const BaseLayout = ({ children }) => {
  return (
    <Container>
      <Navigation />
      {children}
      <script id="CookieDeclaration" src="https://consent.cookiebot.com/5bca8ac3-f428-4c9b-865a-a79bdabaab5a/cd.js" type="text/javascript" async></script>
    </Container>
  );
};

export default BaseLayout;
