import Container from "../Container";
import { faPaperPlane, faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./hero-section.css";

export const HeroSection = () => {
  return (
    <Container className="hero-section-container">
      <div className="hero-section-container__text">
        <p>HELLO THERE, WELCOME TO MY SITE</p>
        <h1 className="hero-section-container__text__basic">I'm Alen Valek</h1>
        <h1 className="hero-section-container__text__colored">
          A Full Stack Developer
        </h1>
        <h1 className="hero-section-container__text__basic">
          & indie game developer, tech enthusiast
        </h1>

        <div className="hero-section-container__interactions">
          <a href="./cv-valek.pdf" download="Valek-CV" className="btn-primary">
            <div className="btn-content">
                <div>Download CV</div>
                <FontAwesomeIcon icon={faPaperPlane} />
            </div>
          </a>
          <a href="mailto:valekalen@gmail.com" className="btn-secondary"> 
          <div className="btn-content">
                <div>Contact me</div>
                <FontAwesomeIcon icon={faMessage} />
            </div></a>
        </div>
      </div>
      <div className="hero-section-container__image">
        <img src="./picture.png" />
      </div>
    </Container>
  );
};
