import React from 'react'
import './home.css'
import BaseLayout from '../../Layouts/BaseLayout'
import { HeroSection } from '../../components/HeroSection'

const Home = () => {
  return (
    <BaseLayout>
      <HeroSection />
    </BaseLayout>
  )
}

export default Home