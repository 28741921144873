import { Link } from "react-router-dom";
import "./navigation.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Navigation = () => {
  return (
    <nav>
      <div className="branding">
        <div className="branding__item branding__title">Alen Valek</div>
        <a href="https://www.linkedin.com/in/alen-valek-b214a2207" target="_blank">
          <FontAwesomeIcon className="branding__item branding__item__icon" icon={faLinkedin} size="xl" />
        </a>
      </div>
      <ul className="nav-mobile is-hidden">
        <li className="navigation__item">
          <Link className="navigation__item__text" to="/">
            Home
          </Link>
        </li>
        <li className="navigation__item">
          <Link className="navigation__item__text" to="/test">
            Work
          </Link>
        </li>
        <li className="navigation__item">
          <Link className="navigation__item__text" to="/">
            Blog
          </Link>
        </li>
        <li className="navigation__item">
          <Link className="navigation__item__text" to="/">
            About me
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
